import {
  faArrowRight,
  faBackward,
  faCoins,
  faForward,
  faMoneyBillWave,
  faServer,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CurrencyRateUpdateData from '../components/currencyRateUpdateData/page'
import CurrencyRateChargeProUpdateData from '../components/currencyRateChargeProUpdateData/page'
import CurrencyUpdateData from '../components/currencyUpdateData/page'
import CurrencyRateChargeCouvUpdateData from '../components/currencyRateChargeCouvUpdateData/page'
import SemiAutoRateUpdateData from '../components/semiAutoRateUpdateData/page'
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';

// Initialise pdfmake avec les polices de caractères
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AdminScreen = (props) => {

  const LineLink = true
  // const LineLink = false

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])

  const history  = useHistory()
  var _ProWallet = localStorage.getItem('ProWallet')
  const userID   = localStorage.getItem('userID')
  const Logged   = localStorage.getItem('userStatus')
  const role     = localStorage.getItem('role')

  const [GetUser,                   setGetUser                 ] = useState([])
  const [User,                      setUser                    ] = useState([])
  const [operatorList,              setOperatorList            ] = useState([])
  const [cinetPayApi,               setCinetPayApi             ] = useState([])
  const [passwordApi,               setPasswordApi             ] = useState([])
  const [ownSiteId,                 setOwnSiteId               ] = useState([])
  const [alertOptions,              setAlertOptions            ] = useState()

  const [oneDisplay,                setOneDisplay              ] = useState()
  const [operatorDisplay,           setOperatorDisplay         ] = useState()
  const [rateDisplay,               setRateDisplay             ] = useState()
  const [semiAutoDisplay,           setSemiAutoDisplay             ] = useState()
  const [currencyDisplay,           setCurrencyDisplay         ] = useState()
  const [chargeDisplay,             setChargeDisplay           ] = useState()
  const [proRateDisplay,            setProRateDisplay          ] = useState()
  const [usersDisplay,              setUsersDisplay            ] = useState()

  const [cryptoMarker,              setCryptoMarker            ] = useState('')
  const [operatorMarker,            setOperatorMarker          ] = useState('')
  const [rateMarker,                setRateMarker              ] = useState('')
  const [chargeMarker,              setChargeMarker            ] = useState('')
  const [currencyMarker,            setCurrencyMarker          ] = useState('')
  const [semiAutoMarker,            setSemiAutoMarker          ] = useState('')
  const [proRateMarker,             setProRateMarker           ] = useState('')
  const [usersMarker,               setUsersMarker             ] = useState('')
  const [counterMarker,             setCounterMarker           ] = useState('')
  const [cryptocounterMarker,       setCryptoCounterMarker     ] = useState('')

  const [orderConfirmationData,     setOrderConfirmationData   ] = useState([])
  const [Operation,                 setOperation               ] = useState([])

  const [isActivated,               setIsActivated             ] = useState()
  const [isActived,                 setIsActived               ] = useState()
  const [activeStatus,              setActiveStatus            ] = useState()

  const [LastData, setLastData] = useState([])



  const activationId = props.location.hash.slice(1, 25)


  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  if (Logged !== 'Logged') {
    props.history.push('/login')
  }

  if (Number(role) !== 1) {
    props.history.push('/')
  }


    
  const seeMoreItemsHandler = () =>{
    setStopGetUser(false)
    setCounter(counter + 10);
  }
  
  const [counter, setCounter] = useState(30);
  const [StopGetUser, setStopGetUser] = useState(false)
  const [AllOp, setAllOp] = useState([])

  useEffect(() => {

    const fetchData = async () => {
        if(!StopGetUser){

          try {
  
              const { data } = await axios.get(
                '/api/v1/get-users',
              )
              if(data.length > 0){
  
                setAllOp(data.length)
                      
                if(data.length > 0 && counter === 0){
                
                  const items = data.slice(0, 30)
              
                  if(items.length > 0){
                    setGetUser(items)
                    setStopGetUser(true)
                  }
              
                } else if(data.length > 0 && counter > 0){
              
                  const items = data.slice(0, counter)
              
                  if(items.length > 0){
                    setGetUser(items)
                    setStopGetUser(true)
                  }
                }
              }
            
          } catch (error) {
            console.log(error)
          }
        }
    }

    fetchData()

  }, [GetUser, counter, StopGetUser])

  useEffect(()=>{

    if(GetUser && userID){
      const userGetted = GetUser.filter((obj) => {
        return obj.ID === Number(userID)
      })
      setUser(userGetted[0])
    }

  },[GetUser, userID, User])
  


  useEffect(() => {

    const fetchCinetPayApiDada = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-cinetpay-api',
        )
        setCinetPayApi(data[0])
      } catch (error) {}

      try {
        const { data } = await axios.get(
          '/api/v1/get-api-pwd',
        )
        setPasswordApi(data[0])
      } catch (error) {}

      try {
        const { data } = await axios.get(
          '/api/v1/get-siteids',
        )
        setOwnSiteId(data[0])
      } catch (error) {
        console.log(error)
      }
    }
    fetchCinetPayApiDada()

  }, [])

  useEffect(() => {

    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/operations',
        )
        if(data.length > 0){
          setOperation(data)
          setOrderConfirmationData(data)
        }
        
      } catch (error) {}
    }

    fetchData()

  }, [Operation])

  function activationHandler() {
    axios.post(
        `/api/v1/services/update`,
        {
          activationId : Number(activationId),
          isActivated : Number(isActivated),
        },
      )
      .then(
        (response) => {
          setAlertOptions(response.data)
        },
        (error) => {
          console.log(error)
        },
      )
  }

  function activateHandler() {
    setIsActivated(1)
    if (isActivated === 1 && activationId) {
      activationHandler()
    }
  }
  function desactivateHandler() {
    setIsActivated(0)
    if (isActivated === 0 && activationId) {
      activationHandler()
    }
  }

  function activationHandler2() {
    axios.post(
        `/api/v1/money-operators/update`,
        {
          activationId : Number(activationId),
          isActived : Number(isActived),
        },
      )
      .then(
        (response) => {
          if(response.data.msg === "success"){

            if(LineLink){
              window.location.replace("https://nkab-exchange.net/dashboard");
            } else {
               window.location.replace("http://localhost:3000/dashboard");
            }
          }
        },
        (error) => {
          console.log(error)
        },
      )
  }

  function activateHandler2() {
    setIsActived(1)
    if (isActived === 1 && activationId) {
      activationHandler2()
    }
  }
  function desactivateHandler2() {
    setIsActived(0)
    if (isActived === 0 && activationId) {
      activationHandler2()
    }
  }

  function alerter() {
    setAlertOptions()
    props.history.push('/#action=dashboard')
  }



  useEffect(() => {
    if (alertOptions) {
      if (alertOptions.isActivated === 1) {
        setActiveStatus('Activé')
        if (activeStatus === 'Activé') {
          alertHandler()
        }
      } else if (alertOptions.isActivated === 0) {
        setActiveStatus('Désactivé')
        if (activeStatus === 'Désactivé') {
          alertHandler()
        }
      }
    }
  }, [])

  function alertHandler() {
    confirmAlert({
      title: `${alertOptions.serviceName}${' '}${activeStatus}`,
      buttons: [
        {
          label: 'Ok',
          onClick: () => alerter(),
        },
      ],
    })
  }




  function semiAutoHandler() {
    setSemiAutoDisplay(true)
    setSemiAutoMarker('options-item-bg')
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyDisplay(false)
    setCurrencyMarker('')
    setRateDisplay(false)
    setProRateDisplay(false)
    setRateMarker('')
    setProRateMarker('')
    setCryptoMarker('')
    setUsersMarker('')
    setOperatorMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setOperatorDisplay(false)
    setOneDisplay(false)
    setUsersDisplay(false)
  }

  function chargeHandler() {
    setChargeDisplay(true)
    setChargeMarker('options-item-bg')
    setCurrencyDisplay(false)
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyMarker('')
    setRateDisplay(false)
    setProRateDisplay(false)
    setRateMarker('')
    setProRateMarker('')
    setCryptoMarker('')
    setUsersMarker('')
    setOperatorMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setOperatorDisplay(false)
    setOneDisplay(false)
    setUsersDisplay(false)
  }

  function currencyHandler() {
    setCurrencyDisplay(true)
    setCurrencyMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setRateDisplay(false)
    setProRateDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setRateMarker('')
    setProRateMarker('')
    setCryptoMarker('')
    setUsersMarker('')
    setOperatorMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setOperatorDisplay(false)
    setOneDisplay(false)
    setUsersDisplay(false)
  }
  function cryptoHandler() {
    setOneDisplay(true)
    setCryptoMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyMarker('')
    setRateMarker('')
    setProRateMarker('')
    setUsersMarker('')
    setOperatorMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setOperatorDisplay(false)
    setRateDisplay(false)
    setProRateDisplay(false)
    setUsersDisplay(false)
  }

  function operatorHandler() {
    setOperatorDisplay(true)
    setOperatorMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyMarker('')
    setRateMarker('')
    setProRateMarker('')
    setUsersMarker('')
    setCryptoMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setOneDisplay(false)
    setRateDisplay(false)
    setProRateDisplay(false)
    setUsersDisplay(false)
  }

  function rateHandler() {
    setRateDisplay(true)
    setProRateDisplay(false)
    setRateMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyMarker('')
    setProRateMarker('')
    setCryptoMarker('')
    setUsersMarker('')
    setOperatorMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setOperatorDisplay(false)
    setOneDisplay(false)
    setUsersDisplay(false)
  }

  function proRateHandler() {
    setProRateDisplay(true)
    setRateDisplay(false)
    setProRateMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyMarker('')
    setRateMarker('')
    setCryptoMarker('')
    setUsersMarker('')
    setOperatorMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setOperatorDisplay(false)
    setOneDisplay(false)
    setUsersDisplay(false)
  }

  function usersHandler() {
    setUsersDisplay(true)
    setUsersMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyMarker('')
    setCryptoMarker('')
    setRateMarker('')
    setProRateMarker('')
    setOperatorMarker('')
    setCounterMarker('')
    setCryptoCounterMarker('')
    setRateDisplay(false)
    setProRateDisplay(false)
    setOperatorDisplay(false)
    setOneDisplay(false)
  }

  function counterHandler() {
    setCounterMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyMarker('')
    setUsersMarker('')
    setCryptoMarker('')
    setRateMarker('')
    setProRateMarker('')
    setOperatorMarker('')
    setCryptoCounterMarker('')
    setRateDisplay(false)
    setProRateDisplay(false)
    setOperatorDisplay(false)
    setOneDisplay(false)
    setUsersDisplay(false)
  }

  function cryptocounterHandler() {
    setCounterMarker('')
    setUsersMarker('')
    setCryptoMarker('')
    setRateMarker('')
    setProRateMarker('')
    setOperatorMarker('')
    setCryptoCounterMarker('options-item-bg')
    setSemiAutoDisplay(false)
    setSemiAutoMarker('')
    setCurrencyDisplay(false)
    setChargeDisplay(false)
    setChargeMarker('')
    setCurrencyMarker('')
    setRateDisplay(false)
    setProRateDisplay(false)
    setOperatorDisplay(false)
    setOneDisplay(false)
    setUsersDisplay(false)
  }

  useEffect(()=>{
    const fetchOperators = async () => {
    try {
        const { data } = await axios.get(
          '/api/v1/get-money-operators',
        )
        if(data.length > 0){
          setOperatorList(data)
        }
    } catch (error) {}

  }
  fetchOperators()
  },[])


  const loadServicesMsg =  "Chargement de services en cours..."
  const loadOperationMsg =  "Chargement d'opération en cours..."
  

  const serviceLists = [
    {
      ID: 1,
      isActivated: 1,
      serviceImageLink: "../images/usdt.png",
      serviceName: "Tether(USDT)",
      serviceSymbol: "USDT"
    },
    {
      ID: 2,
      isActivated: 1,
      serviceImageLink: "../images/bnb.png",
      serviceName: "Binance Smart Chain",
      serviceSymbol: "BNB"
    },
    {
      ID: 3,
      isActivated: 1,
      serviceImageLink: "../images/btc.png",
      serviceName: "Bitcoin",
      serviceSymbol: "BTC"
    },
    {
      ID: 4,
      isActivated: 1,
      serviceImageLink: "../images/ltc.png",
      serviceName: "Litecoin",
      serviceSymbol: "LTC"
    },
    {
      ID: 5,
      isActivated: 1,
      serviceImageLink: "../images/doge.png",
      serviceName: "Dogecoin",
      serviceSymbol: "DOGE"
    },
    {
      ID: 6,
      isActivated: 1,
      serviceImageLink: "../images/payeer.png",
      serviceName: "Payeer",
      serviceSymbol: "PAYEER"
    },
    {
      ID: 7,
      isActivated: 1,
      serviceImageLink: "../images/pm.png",
      serviceName: "Perfect Money",
      serviceSymbol: "PM"
    },
    {
      ID: 8,
      isActivated: 1,
      serviceImageLink: "../images/tron.png",
      serviceName: "TRON",
      serviceSymbol: "trx"
    },
    {
      ID: 9,
      isActivated: 1,
      serviceImageLink: "../images/busd.png",
      serviceName: "Binance USD",
      serviceSymbol: "BUSD"
    },
    {
      ID: 10,
      isActivated: 1,
      serviceImageLink: "../images/shiba.png",
      serviceName: "Shiba Inu",
      serviceSymbol: "SHIB"
    },
    {
      ID: 11,
      isActivated: 1,
      serviceImageLink: "../images/polygonmatic.png",
      serviceName: "Polygon",
      serviceSymbol: "MATIC"
    },
    {
      ID: 12,
      isActivated: 1,
      serviceImageLink: "../images/eth.png",
      serviceName: "Ethereum",
      serviceSymbol: "ETH"
    },
    {
      ID: 13,
      isActivated: 1,
      serviceImageLink: "../images/pokadot.png",
      serviceName: "Polkadot",
      serviceSymbol: "DOT"
    },
    {
      ID: 14,
      isActivated: 1,
      serviceImageLink: "../images/chainlink.png",
      serviceName: "Chainlink",
      serviceSymbol: "LINK"
    },
    {
      ID: 15,
      isActivated: 1,
      serviceImageLink: "../images/cardano.png",
      serviceName: "Cardano",
      serviceSymbol: "ADA"
    },
    {
      ID: 16,
      isActivated: 1,
      serviceImageLink: "../images/solana.png",
      serviceName: "Solana",
      serviceSymbol: "SOL"
    },
    {
      ID: 17,
      isActivated: 1,
      serviceImageLink: "../images/solana.png",
      serviceName: "Solana",
      serviceSymbol: "SOL"
    },
  ]


  useEffect(()=>{
    
    setLastData(GetUser)

  },[GetUser])

// Tableau pour stocker les lignes du tableau
const tableBody = [];

// Ajoute chaque information de la liste au tableau
LastData.forEach((info, index) => {
  tableBody.push([index+1, info.name, info.lastName, info.email, info.telNumber, `${info.updatedAt.slice(0, 10)} à ${Number(info.updatedAt.slice(11,13)) +2}${info.updatedAt.slice(13, 19)}`]);
});

  // Définit la structure du document PDF
  const documentDefinition = {
    content: [
      {
        text: `NKAB Exchange | Liste prévisualisée des utilusateurs`,
        style: 'header',
      },
      {
        table: {
          headerRows: 1,
          // widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'], '*'],
          body: [
            ['N°', 'Nom', 'Prénomss', 'Email', 'Contact', 'Date'],
            ...tableBody
          ]
        },
        fontSize: 8,
      }
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 10]
      },
    }
  };

  const PdfDucument = () => {
    // Génère le document PDF
    // const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    // pdfDocGenerator.open();
  }

  return (
    <div className="container mt-5">
      <div className="main-session">
        <div className="d-flex justify-content-between keys-session">
          <span className="cinetpay-api">
            {!cinetPayApi ? null : cinetPayApi ? (
              <>
                {' '}
                {'CinetPay API :'} {cinetPayApi.cinetPayAPI}{' '}
              </>
            ) : null}
          </span>
          <span className="cinetpay-api">
            {!passwordApi ? null : passwordApi ? (
              <>
                {' '}
                {'API Password :'} {passwordApi.aPIPassword}{' '}
              </>
            ) : null}
          </span>
          <span className="cinetpay-api">
            {!ownSiteId ? null : ownSiteId ? (
              <>
                {' '}
                {'Site ID :'} {ownSiteId.siteId}{' '}
              </>
            ) : null}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <div style={{ marginBottom: `${'10px'}` }}>
            <span
              className='goback'
              onClick={() => history.goBack()}
              style={{ color: '#0a146e', cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faBackward} /> {'Retour'}
            </span>
          </div>
          <div style={{ marginBottom: `${'10px'}` }}>
            <a href="/sell-or-buy-crypto" style={{textDecoration:"none"}}>
              <span
                type="submit"
                className='goback'
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faForward} /> {'Accueil du service'}
              </span>
            </a>
          </div>
        </div>
      </div>

      <section className="">
        <div className="d-flex">
          <div className="admin-options-side">
            <div className="admin-options-side-title">Menu d'options</div>
            <div className={`admin-options-item ${cryptoMarker}`} onClick={cryptoHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Crypto Actifs</span>
            </div>
            <div className={`admin-options-item ${operatorMarker}`} onClick={operatorHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Opérateur Réseau</span>
            </div>
            <div className={`admin-options-item ${currencyMarker}`} onClick={currencyHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Auto Variation</span>
            </div>
            <div className={`admin-options-item ${rateMarker}`} onClick={rateHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Taux Standard</span>
            </div>
            <div className={`admin-options-item ${proRateMarker}`} onClick={proRateHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Taux Pro</span>
            </div>
            <div className={`admin-options-item ${chargeMarker}`} onClick={chargeHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Charge de couv</span>
            </div>
            <div className={`admin-options-item ${semiAutoMarker}`} onClick={semiAutoHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Semi Auto couv</span>
            </div>
            <div className={`admin-options-item ${usersMarker}`} onClick={usersHandler} >
              <span className="admin-option-button" style={{fontSize:'13.5px'}}>Utilisateurs</span>
            </div>
          </div>
          <div className="admin-display-side table-section">
            {oneDisplay === true ? (
              <div>
                <a href="/newservice" className="admin-add-crypto my_rate_button">
                  Crypto actif <strong className="color-white">+</strong>
                </a>
                <div className="_admin-display-card-section pt-3">
                  {!serviceLists ? <LoadingBox loadServicesMsg={loadServicesMsg} /> : serviceLists.map((item) => (
                    <div key={item.ID} className='di'>
                      {item.serviceSymbol === 'ntr' ? null : (
                        <div className="card admin-display-card">
                          <div className="crypto-card-img-container">
                            <img
                              src={item.serviceImageLink}
                              className="crypto-card-img"
                              alt={item.serviceName}
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">{item.serviceName}</h5>
                            <p className="card-text color-r">
                              Cliquer deux fois pour activer ou désactiver!
                            </p>
                            <div className="d-flex justify-content-center">
                              {item.isActivated === 1 ? (
                                <a
                                  href={`#${item.ID}`}
                                  className="btn btn-success admin-display-button ml-2 mr-2"
                                  style={{backgroundColor:'#157347'}}
                                  onClick={desactivateHandler}
                                >
                                  Activé
                                </a>
                              ) : item.isActivated === 0 ? (
                                <a
                                  href={`#${item.ID}`}
                                  className="btn btn-secondary admin-display-button ml-2 mr-2"
                                  style={{backgroundColor:'#4e4e4e'}}
                                  onClick={activateHandler}
                                >
                                  Désactivé
                                </a>
                              ) : null}
                              <a
                                href={`#${item.ID}`}
                                className="btn btn-danger admin-display-button ml-2 mr-2"
                                style={{backgroundColor:'#bb2d3b'}}
                                // onClick={deleteServiceHandle}
                              >
                                Supprimer
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : operatorDisplay === true ? (
              <div>
                <div className='row'>
                  <div className='col-md-2 mb-3 mt-2'>
                    <a href="/newoperator" className="admin-add-crypto my_rate_button">
                      Paiement <strong className="color-white">+</strong>
                    </a>
                  </div>
                </div>
                <div className="_admin-display-card-section pt-3">
                  {!operatorList ? <LoadingBox loadServicesMsg={loadOperationMsg} /> : operatorList.map((item) => (
                    <div key={item.ID}>
                      {item.operatorSymbol === 'XXX' ? null : (
                        <div className="text-center align-items-center card admin-display-card">
                          <div className='p-2'>
                            <img src={item.operatorImageLink} className="crypto-card-img" alt={item.operatorName} />
                          </div>
                          <div className="card-body"> <h5 className="card-title">{item.operatorName}</h5> </div>
                          <div className="card-body">
                            <p className="card-text color-r"> Cliquer deux fois pour activer ou désactiver! </p>
                            <div className="d-flex justify-content-center">
                              {item.isActived === 1 ? (
                                <a
                                  href={`#${item.ID}`}
                                  className="btn btn-success admin-display-button ml-2 mr-2"
                                  style={{backgroundColor:'#157347'}}
                                  onClick={desactivateHandler2}
                                >
                                  Activer
                                </a>
                              ) : item.isActived === 0 ? (
                                <a
                                  href={`#${item.ID}`}
                                  className="btn btn-secondary admin-display-button ml-2 mr-2"
                                  style={{backgroundColor:'#4e4e4e'}}
                                  onClick={activateHandler2}
                                >
                                  Désactiver
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : currencyDisplay === true ? (
              <div className="_admin-display-card-section pt-3">
                <CurrencyUpdateData themeMode={themeMode} LineLink={LineLink} />
              </div>
            ) : rateDisplay === true ? (
              <div className="_admin-display-card-section pt-3">
                <CurrencyRateUpdateData themeMode={themeMode} LineLink={LineLink} />
              </div>
            ) : proRateDisplay === true ? (
              <div className="_admin-display-card-section pt-3">
                <CurrencyRateChargeProUpdateData themeMode={themeMode} LineLink={LineLink} />
              </div>
            ) :  chargeDisplay === true ? (
              <div className="_admin-display-card-section pt-3">
                <CurrencyRateChargeCouvUpdateData themeMode={themeMode} LineLink={LineLink} />
              </div>
            ) :  semiAutoDisplay === true ? (
              <div className="_admin-display-card-section pt-3">
                <SemiAutoRateUpdateData themeMode={themeMode} LineLink={LineLink} />
              </div>
            ) : usersDisplay === true ? (
              <div className="admin-display-sell-section">
                <div>
                  <span className='pointer'>
                    <Button onClick={PdfDucument} variant="contained" size="small"> Imprimer </Button>
                  </span>
                </div>
                <div className="__op-body-table">
                  <TableContainer component={Paper} style={{fontSize:"25px", borderRadius:"10px", backgroundColor:`${themeMode === "true" ? "#0E233A" : ""}`}}>
                    <Table sx={{ minWidth: 650 }} aria-label="nkab users table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Email</TableCell>
                          <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} >Noms</TableCell>
                          <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} >Prénoms</TableCell>
                          <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} >Téléphone</TableCell>
                          <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} >Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {GetUser.map((item) => (
                          <TableRow key={item.ID}>
                            <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{item.email}</TableCell>
                            <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{item.name}</TableCell>
                            <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} >{item.lastName}</TableCell>
                            <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} >{item.telNumber}</TableCell>
                            <TableCell style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} >{item.updatedAt.slice(0,10)} à {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13,19)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <div>
                      <div className='text-center p-2'><span className="stat-btn-see-more" onClick={seeMoreItemsHandler}>Voir plus <FontAwesomeIcon icon={faArrowRight} /></span></div>
                    </div>
                  </TableContainer>
                </div>
              </div>
            ) : (
              <div className="p-2">
                <div className="row mb-4">
                  <div className='col-md-3 d-flex justify-content-around'>
                    <div className='mr-2'><FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 40, color: `${'#7a9fc7'}` }} /></div>
                    <div className="card-body">
                      <h5 className="card-title" style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Utilisateurs</h5>
                      <p className="card-text color-r">
                        {
                          AllOp > 0 ? 
                          <span> <strong className="strong-length"> {AllOp} </strong> </span> :
                          <span> 0 </span>
                        }
                      </p>
                    </div>
                  </div>
                  <div className='col-md-3  d-flex justify-content-around'>
                    <div className='mr-2'><FontAwesomeIcon icon={faServer} style={{ fontSize: 40, color: `${'#7a9fc7'}` }} /></div>
                    <div className="card-body">
                      <h5 className="card-title" style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Opérations</h5>
                      <p className="card-text color-r">
                        {
                          !Operation?null:Operation.length > 0 ? 
                          ( <span> <strong className="strong-length"> {!Operation?null:Operation.length} </strong> </span> ) :
                          ( <span> 0 </span> )
                        }
                      </p>
                    </div>
                  </div>
                  <div className='col-md-3  d-flex justify-content-around'>
                    <div className='mr-2'><FontAwesomeIcon icon={faCoins} style={{ fontSize: 40, color: `${'#7a9fc7'}` }} /></div>
                    <div className="card-body">
                      <h5 className="card-title" style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Crypto Actifs</h5>
                      <p className="card-text color-r">
                        {
                          !serviceLists?null:serviceLists.length > 0 ? 
                          ( <span> <strong className="strong-length"> {!serviceLists?null:serviceLists.length} </strong> </span> ) :
                          ( <span> 0 </span> )
                        }
                      </p>
                    </div>
                  </div>
                  <div className='col-md-3  d-flex justify-content-around'>
                    <div className='mr-2'><FontAwesomeIcon icon={faMoneyBillWave} style={{ fontSize: 40, color: `${'#7a9fc7'}` }} /></div>
                    <div className="card-body">
                      <h5 className="card-title" style={{fontSize:"14px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Moyens de paiement</h5>
                      <p className="card-text color-r">
                        {
                          !operatorList?null:operatorList.length > 0 ? 
                          ( <span> <strong className="strong-length"> {!operatorList?null:operatorList.length} </strong> </span> ) :
                          ( <span> 0 </span> )
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default AdminScreen
